import * as React from "react";
import {
  FooterTitle,
  FooterBlock,
  FooterWrapper,
  FooterFooter,
} from "./footer.style";

type FooterProps = {};

const Footer: React.FunctionComponent<FooterProps> = ({ ...props }) => {
  return (
    <FooterWrapper>
      <FooterBlock>
        <FooterTitle>Contacto</FooterTitle>
        <a href="https://linktr.ee/djpaulpe">https://linktr.ee/djpaulpe</a>
        <br />
        <a href="https://bit.ly/2YTTnDO" rel="noreferrer" target="_blank">
          +51 984 709 688
        </a>
      </FooterBlock>
      <FooterBlock>
        <FooterTitle>Sígueme en redes sociales</FooterTitle>
        <a
          href="https://www.instagram.com/djpaulpe/"
          rel="noreferrer"
          target="_blank"
        >
          Instagram
        </a>
        <a
          href="https://www.facebook.com/djpaulperu"
          rel="noreferrer"
          target="_blank"
        >
          Facebook
        </a>
      </FooterBlock>
      <FooterFooter></FooterFooter>
    </FooterWrapper>
  );
};

export default Footer;
