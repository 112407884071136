import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type SEOProps = {
  description?: string;
  lang?: string;
  meta?: any;
  keywords?: any;
  title: string;
};

const SEO: React.FunctionComponent<SEOProps> = ({
  description,
  lang,
  meta,
  keywords,
  title,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  keywords: [
    "alquiler de equipos de sonido",
    "dj paul",
    "musica para matrimonios",
    "musica para bodas dj",
    "dj de resident",
    "dj de qui",
    "dj de cochinola",
  ],
  description: `Dj Paul es residente de las mejores discotecas de Lima, toca en matrimonios y recorre el Perú presentándose en los eventos y discotecas más importantes.`,
};

export default SEO;
