import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const FooterWrapper = styled.footer`
  margin: auto;
  padding: 50px 75px 0 75px;
  position: relative;
  overflow: hidden;
  background-color: ${themeGet("colors.black", "#292929")};
  color: ${themeGet("colors.white", "#ffffff")};
  @media (max-width: 1400px) {
    padding: 100px 35px 0 35px;
  }
  @media (max-width: 990px) {
    padding: 50px 25px 0 25px;
  }
  a {
    padding-right 25px;
    white-space: nowrap;
    font-size: 60px;
    line-height: 86px;
    font-weight: ${themeGet("fontWeights.4", "500")};
    color: ${themeGet("colors.white", "#ffffff")};
    transition: 0.15s ease-in-out;
    &:hover {
      color: ${themeGet("colors.lightTextColor", "#757575")};
    }
    &.active-link {
      color: ${themeGet("colors.textColor", "#292929")};
      font-weight: ${themeGet("fontWeights.4", "500")};
    }
    @media (max-width: 600px) {
      line-height: 43px;
      font-size: 36px;
    }
  }
`;

export const FooterBlock = styled.div`
  margin: 80px 0;
  @media (max-width: 600px) {
    margin: 40px 0;
  }
`;

export const FooterTitle = styled.h1`
  line-height: 43px;
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 23px;
  }
`;

export const FooterFooter = styled.div`
  margin: 120px 0 60px 0;
  @media (max-width: 1400px) {
    margin: 220px 0 60px 0;
  }
  @media (max-width: 600px) {
    margin: 80px 0 50px 0;
  }
`;
