import { createGlobalStyle } from "styled-components";

const ResetCSS = createGlobalStyle`
  html {
    font-size: 15px;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    margin: 0;
    font-family: 'Montserrat',sans-serif;
  }

  h2{
    padding: 90px 75px 0 75px;
    width: 730px;
    min-height: 99px;
    font-size: 96px;
    line-height: 96px;
    text-indent: -5px;
    color: #292929;
    font-family: Montserrat, sans-serif;
    letter-spacing: -1px;
    position: relative;
    background-color: transparent;
    font-weight: 700;
    @media (max-width: 600px) {
      padding: 10px 15px 0 15px;
      width: 100%;
      min-height: 74px;
      font-size: 52px;
      line-height: 72px;
      text-indent: -5px;
    }
  }

  .post_title{
    width: 100%;
  }

  h3{
    padding: 0 75px 90px 75px;
    color: #757575;
    line-height: 36px;
    font-size: 36px;
    text-indent: -2px;
    font-weight: 300;
    @media (max-width: 600px) {
      padding: 0 15px 10px 15px;
      line-height: 24px;
      width: 100%;
    font-size: 24px;
    }
  }

  body {
    font-family: 'Oswald',sans-serif;
    margin: 0;
    padding: 0;
    font-size: 15px;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }

  p{
    line-height: 25px;
    font-size: 18px;
    margin: 0 0 1.7em 0;
    font-weight: 300;
  }

  input, textarea, select, button{font-family: 'Oswald',sans-serif;}

  ul,ol {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    box-shadow: none;
  }

  a:hover {
    text-decoration: none;
  }

  blockquote{
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
  }

  :not(pre) > code[class*="language-"], pre[class*="language-"] {
    background: #f5f5f5;
  }

  pre[class*="language-"] {
    padding: 1.5em;
    margin: 2em 0;
    overflow: auto;
  }

  iframe {
    margin-bottom: 20px;
  }

  table {
    display: block;
    overflow: auto;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 2.7em;
    line-height: 1.6;
  }

  table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }

  table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  table th{
    font-weight: 500;
  }

  table td,
  table th {
    border: 1px solid #dfe2e5;
    padding: .4em 1em;
  }

  .nav-sticky{
    .header {
      box-shadow: 0 0 15px rgba(0,0,0,.08);
      .navbar{
        @media (min-width: 1400px) {
          padding-top: 25px;
          padding-bottom: 25px;
        }
        @media (min-width: 1200px) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }
`;
export default ResetCSS;
